import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { getStringFromDate } from "../utils/strings";

class PostList extends React.Component {
  getPostList() {
    const { postEdges } = this.props;
    const postList = [];

    postEdges.forEach(postEdge => {
      const categories = [];
      postEdge.node.categories.forEach(category => {
        categories.push(category.name);
      });

      let pathPrefix = "news";
      if (categories.includes("Blog")) {
        pathPrefix = "blog";
      }

      postList.push({
        id: postEdge.node.id,
        path: postEdge.node.slug,
        cover: postEdge.node.cover,
        title: postEdge.node.title,
        date: postEdge.node.acf.external_published_date
          ? postEdge.node.acf.external_published_date
           : postEdge.node.date,
        excerpt: postEdge.node.excerpt,
        category: pathPrefix,
      });
    });

    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <PostListContainer>
        {postList.map(post => {
          return (
            <PostListItemContainer key={post.path}>
              <Link
                className="post-link"
                to={`/${post.category}/${post.path}`}
                key={post.title}
              >
                <Headline dangerouslySetInnerHTML={{ __html: post.title }} />
              </Link>
              <PostDate>{getStringFromDate(post.date)}</PostDate>
              {" "}
              <PostContent dangerouslySetInnerHTML={{ __html: post.excerpt }} />
              {" "}
            </PostListItemContainer>
          );
        })}
      </PostListContainer>
    );
  }
}
const PostListContainer = styled.ul`
  list-style: none;
`;
const PostListItemContainer = styled.li`
  margin-bottom: 3rem;
  margin-left: 0;
`;

const Headline = styled.h2`
  max-width: 50rem;
`;

const PostDate = styled.h6`
  margin: 0.2rem 0 1rem;
`;

const PostContent = styled.section`
  img {
    max-width: 100%;
    height: auto;
  }
`;
export default PostList;
